<template>
  <el-drawer
    :title="id ? '修改规则' : '创建规则'"
    :visible.sync="isOpenAddAutoRulesDraw"
    direction="rtl"
    :before-close="handleClose"
    :size="900"
  >
    <el-container>
      <el-main>
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="rulesForm"
          label-width="100px"
          class="demo-ruleForm"
          label-position="top"
        >
          <el-form-item
            label="规则名称"
            prop="spec.name"
          >
            <el-input
              v-model="ruleForm.spec.name"
              clearable
            ></el-input>
          </el-form-item>
          <div class="bg-theme block bottom-border">
            <p>作用范围</p>
            <div class="dp-flex align-item-c">
              <el-form-item
                label="平台"
                required
                class="mr-10"
              >
                <el-select
                  v-model="ruleForm.spec.platform"
                  placeholder="请选择"
                  @change="handlePlatformChange"
                >
                  <el-option
                    :label="item.label"
                    :value="item.value"
                    v-for="(item, index) in platformList"
                    :key="index"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                label="作用对象"
                required
                class="mr-10"
              >
                <el-select
                  v-model="ruleForm.spec.target_type"
                  placeholder="请选择"
                  :disabled="
                    ruleForm.spec.platform != 'facebook' &&
                    ruleForm.spec.platform != 'tiktok' &&
                    ruleForm.spec.platform != 'line' &&
                    ruleForm.spec.platform != 'snapchat'
                  "
                  @change="handelTargetType"
                >
                  <el-option
                    :label="item.label"
                    :value="item.value"
                    v-for="(item, index) in targetTypeList"
                    :key="index"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="作用对象细分">
                <el-checkbox
                  label="仅作用于指定的账号或系列"
                  v-model="isObjectSubdivision"
                ></el-checkbox>
              </el-form-item>
            </div>

            <el-form-item
              v-if="isObjectSubdivision"
              label="细分条件(同时满足所有条件)"
            >
              <div
                v-for="(item, index) in ruleForm.spec.attr_filters"
                :key="index"
              >
                <!-- @change="
                    (val) => {
                      attrFiltersSelectChange(val, index);
                    }
                  " -->
                <div class="dp-flex align-item-c">
                  <!-- <div class="mr-10" v-if="index !== 0">且</div> -->
                  <el-select
                    v-model="item.field"
                    class="mr-10"
                    @change="
                      (val) => {
                        attrFiltersSelectChange(val, index);
                      }
                    "
                  >
                    <!-- :disabled="attr.disabled" -->
                    <el-option
                      :label="attr.desc"
                      :value="attr.field"
                      v-for="(attr, attrIndex) in attrFiltersList"
                      :key="attrIndex"
                      :disabled="attr.disabled"
                    ></el-option>
                  </el-select>
                  <el-select
                    v-if="item.field !== 'campaign_name'"
                    v-model="item.operator"
                    class="mr-10"
                  >
                    <!-- :disabled="attr.disabled" -->
                    <el-option
                      :label="attr.label"
                      :value="attr.value"
                      v-for="(attr, attrIndex) in attrFiltersOperatorList"
                      :key="attrIndex"
                    ></el-option>
                  </el-select>
                  <el-select
                    v-if="item.field == 'campaign_name'"
                    v-model="item.operator"
                    class="mr-10"
                  >
                    <!-- :disabled="attr.disabled" -->
                    <el-option
                      :label="attr.label"
                      :value="attr.value"
                      v-for="(attr, attrIndex) in attrFiltersCampaignNameOperatorList"
                      :key="attrIndex"
                    ></el-option>
                  </el-select>
                  <el-input
                    v-model="item.value"
                    v-if="
                      item.field === 'account_ids' ||
                      item.field === 'product_ids' ||
                      item.field === 'sale_ids' ||
                      item.field === 'site_ids'
                    "
                    placeholder="请输入id（多个id请使用英文 “,” 隔开）"
                    class="mr-10 inputWd"
                  ></el-input>
                  <el-select
                    multiple
                    v-if="item.field === 'campaign_ids'"
                    v-model="item.value"
                    placeholder="请输入广告系列id或者名称关键字搜索"
                    filterable
                    remote
                    :remote-method="
                      (val) => {
                        remoteMethod(val, item.value);
                      }
                    "
                    @change="changeCampaign"
                    class="mr-10 selectWidth"
                  >
                    <el-option
                      :label="attr.campaignName"
                      :value="attr.campaignId"
                      v-for="(attr, attrIndex) in campaignList"
                      :key="attrIndex"
                    ></el-option>
                  </el-select>
                  <el-input
                    v-model="item.value"
                    v-if="item.field === 'campaign_name'"
                    placeholder="请输入广告系列名称"
                    class="mr-10 inputWd"
                  ></el-input>
                  <!-- <el-select
                    v-if="item.field === 'campaign_name'"
                    v-model="item.value"
                    filterable
                    remote
                    :remote-method="remoteMethodCampaignName"
                    class="mr-10 selectWidth"
                  >
                    <el-option
                      :label="attr.campaignName"
                      :value="attr.campaignName"
                      v-for="(attr, attrIndex) in campaignNameList"
                      :key="attrIndex"
                    ></el-option>
                  </el-select> -->
                  <!-- <el-select
                    multiple
                    clearable
                    v-if="item.field === 'account_currency'||item.field === 'country_codes'||item.field === 'area_ids'||item.field === 'smart_promotion_type'"
                    v-model="item.value"
                    filterable
                    class="mr-10 selectWidth"
                    @change="handleOptionCurrencyChange"
                  >
                    <el-option
                      :label="attr[item.labelName]"
                      :value="attr[item.valueName].toString()"
                      v-for="(attr, attrIndex) in attrChangeableList(item)"
                      :key="attrIndex"
                    ></el-option>
                  </el-select> -->
                  <el-select
                    multiple
                    clearable
                    v-if="item.field === 'account_currency'"
                    v-model="item.value"
                    filterable
                    class="mr-10 selectWidth"
                    @change="handleOptionCurrencyChange"
                  >
                    <el-option
                      :label="attr.name"
                      :value="attr.code"
                      v-for="(attr, attrIndex) in currencyList"
                      :key="attrIndex"
                    ></el-option>
                  </el-select>

                  <el-select
                    multiple
                    v-if="item.field === 'country_codes'"
                    v-model="item.value"
                    filterable
                    class="mr-10 selectWidth"
                  >
                    <el-option
                      :label="attr.name"
                      :value="attr.code"
                      v-for="(attr, attrIndex) in countryList"
                      :key="attrIndex"
                    ></el-option>
                  </el-select>
                  <el-select
                    multiple
                    v-if="item.field === 'area_ids'"
                    v-model="item.value"
                    filterable
                    class="mr-10 selectWidth"
                  >
                    <el-option
                      :label="attr.name"
                      :value="attr.id.toString()"
                      v-for="(attr, attrIndex) in teamIdList"
                      :key="attrIndex"
                    ></el-option>
                  </el-select>
                  <el-select
                    multiple
                    v-if="item.field === 'smart_promotion_type'"
                    v-model="item.value"
                    filterable
                    class="mr-10 selectWidth"
                  >
                    <el-option
                      :label="attr.label"
                      :value="attr.value"
                      v-for="(attr, attrIndex) in promotionTypeList"
                      :key="attrIndex"
                    ></el-option>
                  </el-select>
                  <i
                    class="el-icon-delete"
                    @click="delAttrFilters(index)"
                  ></i>
                </div>
                <div
                  class="small-gray ml-10"
                  :class="tipsMap[item.field] ? '' : 'mb-20'"
                >
                  {{ tipsMap[item.field] }}
                </div>
              </div>
              <el-button
                @click="addAttrFilters"
                type="text"
                >+添加条件</el-button
              >
            </el-form-item>
          </div>
          <div class="mb-20">
            <p class="mb-20">操作</p>
            <el-tabs
              v-model="editableTabsValue"
              type="border-card"
              @tab-remove="removeTab"
              :before-leave="beforeLeave"
              @keydown.native="handleKeyDown"
              class="edit_tab"
              ref="elTabs"
              v-if="editableTabsValue"
            >
              <el-tab-pane
                v-for="(ruleItems, ruleItemsIndex) in ruleForm.spec.rule_items"
                :key="ruleItemsIndex"
                :label="ruleItems.name"
                :name="ruleItems.name"
                :closable="ruleForm.spec.rule_items.length > 1 && !ruleItems.editNamePopVisible"
              >
                <span slot="label">
                  <!-- v-if="!ruleItems.editNamePopVisible" -->
                  <span class="mr-10">{{ ruleItems.name }}</span>
                  <!-- <i class="el-icon-edit" v-if="!ruleItems.editNamePopVisible"
                    @click="editTabsName(ruleItems, ruleItemsIndex)"></i> -->

                  <!-- <el-input
                    v-show="ruleItems.editNamePopVisible"
                    ref="editNameInput"
                    v-model="ruleItems.editName"
                    size="mini"
                    :minlength="1"
                    :maxlength="20"
                    show-word-limit
                    class="edit_tab_input"
                  ></el-input> -->
                  <!-- <i class="el-icon-circle-check ml-20" v-if="ruleItems.editNamePopVisible"
                    @click="handelTabNameInputChange(ruleItems, ruleItemsIndex)"></i> -->
                  <el-popover
                    ref="popover"
                    placement="top"
                    width="300"
                    trigger="click"
                    v-model="ruleItems.editNamePopVisible"
                  >
                    <div class="dp-flex">
                      <el-input
                        v-show="ruleItems.editNamePopVisible"
                        ref="editNameInput"
                        v-model="ruleItems.editName"
                        size="mini"
                        :minlength="1"
                        :maxlength="20"
                        show-word-limit
                        class="edit_tab_input"
                      ></el-input>
                      <el-button
                        size="mini"
                        type="info"
                        class="ml-10"
                        @click="ruleItems.editNamePopVisible = false"
                        icon="el-icon-close"
                      ></el-button>
                      <el-button
                        type="primary"
                        size="mini"
                        icon="el-icon-check"
                        @click="handelTabNameInputChange(ruleItems, ruleItemsIndex)"
                      ></el-button>
                    </div>
                    <i
                      slot="reference"
                      class="el-icon-edit"
                      @click="editTabsName(ruleItems, ruleItemsIndex)"
                    ></i>
                  </el-popover>
                </span>
                <el-form-item required>
                  <span slot="label">
                    条件
                    <el-tooltip
                      effect="dark"
                      placement="top"
                    >
                      <div slot="content">
                        上线天数：最近15天内首次产生花费日期距离现在的天数。上1小时：当前时间上1小时00~59分，例如：12:30的上1小时为11~12点<br />当前小时：开始时间为当前小时0分0秒，有效时间，最近0-30分钟。最近2小时：开始时间为当前小时0分0秒
                        减1小时，有效时间，最近1-1.5小时<br />
                        最近3小时：开始时间为当前小时0分0秒
                        减2小时，有效时间，最近2-2.5小时。最近4小时：开始时间为当前小时0分0秒
                        减3小时，有效时间，最近3-3.5小时<br />
                        最近5小时：开始时间为当前小时0分0秒
                        减4小时，有效时间，最近4-4.5小时。最近6小时：开始时间为当前小时0分0秒
                        减5小时，有效时间，最近5-5.5小时<br />
                        小时规则注意：自动规则的运行时机是每小时2分、32分；所以当前小时包含的时间为0分或30分钟
                      </div>
                      <i class="el-icon-question" />
                    </el-tooltip>
                    <div class="small-gray lh-20">同时满足所有条件</div>
                  </span>
                  <div
                    v-for="(item, metricIndex) in ruleItems.metric_filters"
                    :key="metricIndex"
                    class="dp-flex align-item-c mb-20"
                  >
                    <el-select
                      v-model="item.field"
                      class="mr-10 w-270"
                      filterable
                      @change="metricFiltersChange(item, ruleItems)"
                    >
                      <!-- @change="updateMetricFiltersList(item)" -->
                      <!-- :disabled="item.disabled" -->
                      <el-option
                        :label="metricFiltersItem.desc"
                        :value="metricFiltersItem.field"
                        v-for="(metricFiltersItem, index) in metricFiltersList"
                        :key="index"
                      ></el-option>
                    </el-select>
                    <el-select
                      class="mr-10 w-160"
                      v-model="item.date_preset"
                      :disabled="item.field == 'upline_days'"
                    >
                      <el-option
                        :label="item"
                        :value="item"
                        v-for="(item, index) in dateInsideList"
                        :key="index"
                      ></el-option>
                    </el-select>
                    <el-select
                      v-model="item.operator"
                      class="mr-10 operatorInput"
                    >
                      <el-option
                        :label="operator"
                        :value="operator"
                        v-for="(operator, operatorIndex) in operatorList"
                        :key="operatorIndex"
                      ></el-option>
                    </el-select>
                    <div class="pr dp-flex">
                      <el-tooltip
                        class="item"
                        effect="dark"
                        :content="item.value.toString()"
                        placement="top-start"
                      >
                        <el-input
                          v-model="item.value"
                          class="mr-10 numberInput"
                          :disabled="item.value_type == 'expression'"
                          @input="
                            (val) => {
                              handleInput(val, item, 'value');
                            }
                          "
                        >
                          <i
                            v-if="item.field == 'pre_profit_rate' || item.field == 'ctr'"
                            slot="suffix"
                            >%</i
                          >
                          <el-button
                            slot="append"
                            type="primary"
                            icon="el-icon-edit"
                            v-if="item.value_type == 'expression'"
                            @click="changeExpression(metricIndex, item.value,ruleItemsIndex)"
                          ></el-button>
                        </el-input>
                      </el-tooltip>
                      <el-button
                        slot="reference"
                        v-if="item.value_type !== 'expression'"
                        class="w-112"
                        @click="changeExpression(metricIndex, item.value,ruleItemsIndex)"
                        >使用表达式</el-button
                      >
                      <expression
                        :ref="`expression-${ruleItemsIndex}-${metricIndex}`"
                        :metricIndex="metricIndex"
                        :ruleItemsIndex="ruleItemsIndex"
                        :dateList="dateList"
                        :metricList="metricFiltersList"
                        @f_expressionSure="f_expressionSure"
                      ></expression>
                      <el-button
                        v-if="item.value_type == 'expression'"
                        @click="useNormal(item)"
                        class="w-112"
                        >使用数值</el-button
                      >
                    </div>

                    <!-- -
                <el-input
                  v-model.number="item.value1"
                  class="mr-10 ml-10 numberInput"
                  @input="validateMax(item)"
                >
                  <i slot="suffix">%</i>
                </el-input> -->
                    <i
                      class="el-icon-delete"
                      @click="delMetricFilters(ruleItemsIndex, metricIndex)"
                    ></i>
                  </div>
                  <el-button
                    @click="addMetricFilters(ruleItemsIndex)"
                    type="text"
                    >+添加条件</el-button
                  >
                </el-form-item>
                <el-form-item
                  label="时间周期"
                  required
                >
                  <el-select v-model="ruleItems.date_preset">
                    <el-option
                      :label="item"
                      :value="item"
                      v-for="(item, index) in dateList"
                      :key="index"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <div class="dp-flex align-item-c">
                  <el-form-item
                    label="执行操作"
                    required
                  >
                    <el-select
                      v-model="ruleItems.action_type"
                      class="mr-10"
                      @change="actionTypeChange(ruleItems, ruleItemsIndex)"
                    >
                      <el-option
                        :label="item"
                        :value="item"
                        v-for="(item, index) in actionTypeList"
                        :key="index"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item required>
                    <span slot="label">
                      操作间隔时长(小时)
                      <el-tooltip
                        effect="dark"
                        content="同一个系列触发同一规则，执行多次操作之间的时间间隔。比如：同一个系列多次触发加预算时，两个加预算操作需要等几个小时"
                        placement="top"
                      >
                        <i class="el-icon-question" />
                      </el-tooltip>
                    </span>
                    <el-select v-model="ruleItems.action_interval_minute">
                      <el-option
                        v-for="n in 24"
                        :key="n"
                        :label="n.toString()"
                        :value="n * 60"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </div>
                <div
                  v-if="ruleItems.action_type === '调整日预算' || ruleItems.action_type === '调整竞价'"
                  class="dp-flex align-item-c"
                >
                  <el-form-item
                    :label="ruleItems.action_type === '调整日预算' ? '日预算' : '竞价'"
                    required
                  >
                    <el-select
                      v-model="ruleItems.change_budget.change_type"
                      class="mr-10"
                      @change="changeBudgetType(ruleItems, ruleItemsIndex)"
                    >
                      <el-option
                        :label="item.label"
                        :value="item.value"
                        v-for="(item, index) in changeTypeList"
                        :key="index"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item
                    v-if="ruleItems.change_budget.change_type !== 'SET'"
                    class="mt-50"
                  >
                    <el-select
                      v-model="ruleItems.change_budget.budget"
                      class="mr-10"
                    >
                      <el-option
                        :label="item"
                        :value="item"
                        v-for="(item, index) in budgetList"
                        :key="index"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item class="mt-50">
                    <el-input
                      class="operatorInput mr-20"
                      v-model="ruleItems.change_budget.change_value"
                      :suffix-icon="ruleItems.change_budget.change_type === 'AMOUNT' ? 'el-icon-coin' : ''"
                      @input="
                        (val) => {
                          handleInput(val, ruleItems.change_budget, 'change_value');
                        }
                      "
                      ><i
                        v-if="ruleItems.change_budget.change_type === 'PERCENT'"
                        slot="suffix"
                        >%</i
                      ></el-input
                    >
                  </el-form-item>
                  <el-form-item
                    v-if="ruleItems.change_budget.change_type !== 'SET'"
                    :label="ruleItems.change_budget.budget === '提高' ? '上限' : '下限'"
                    class="operatorInput"
                  >
                    <el-input
                      v-if="ruleItems.change_budget.budget === '提高'"
                      v-model="ruleItems.change_budget.max_value"
                      suffix-icon="el-icon-coin"
                      @input="
                        (val) => {
                          handleInput(val, ruleForm.spec.rule_items[ruleItemsIndex].change_budget, 'max_value');
                        }
                      "
                    >
                    </el-input>
                    <el-input
                      v-if="ruleItems.change_budget.budget === '降低'"
                      v-model="ruleItems.change_budget.min_value"
                      suffix-icon="el-icon-coin"
                      @input="
                        (val) => {
                          handleInput(val, ruleForm.spec.rule_items[ruleItemsIndex].change_budget, 'min_value');
                        }
                      "
                    >
                    </el-input>
                  </el-form-item>
                </div>
                <div
                  class="dp-flex align-item-c"
                  v-if="ruleItems.action_type === '复制'"
                >
                  <el-form-item
                    label="复制份数"
                    required
                    class="mr-10"
                  >
                    <el-input-number
                      v-model="ruleItems.copy_option.copies"
                      :min="1"
                      :max="10"
                      :precision="0"
                    ></el-input-number>
                  </el-form-item>
                  <el-form-item
                    class="mr-10"
                    label="系列日预算"
                  >
                    <el-input
                      class="w-100"
                      v-model="ruleItems.copy_option.campaign_daily_budget"
                      @input="
                        (val) => {
                          handleInput(
                            val,
                            ruleForm.spec.rule_items[ruleItemsIndex].copy_option,
                            'campaign_daily_budget',
                          );
                        }
                      "
                    ></el-input>
                  </el-form-item>
                  <el-form-item
                    class="mr-10"
                    label="组日预算"
                  >
                    <el-input
                      class="w-100"
                      v-model="ruleItems.copy_option.adset_daily_budget"
                      @input="
                        (val) => {
                          handleInput(val, ruleForm.spec.rule_items[ruleItemsIndex].copy_option, 'adset_daily_budget');
                        }
                      "
                    ></el-input>
                  </el-form-item>
                  <el-form-item
                    class="mr-10"
                    label="系列竞价/出价"
                  >
                    <el-input
                      class="w-100"
                      v-model="ruleItems.copy_option.campaign_bid_amount"
                      @input="
                        (val) => {
                          handleInput(val, ruleForm.spec.rule_items[ruleItemsIndex].copy_option, 'campaign_bid_amount');
                        }
                      "
                    ></el-input>
                  </el-form-item>
                  <el-form-item
                    class="mr-10"
                    label="组竞价/出价"
                  >
                    <el-input
                      class="w-100"
                      v-model="ruleItems.copy_option.adset_bid_amount"
                      @input="
                        (val) => {
                          handleInput(val, ruleForm.spec.rule_items[ruleItemsIndex].copy_option, 'adset_bid_amount');
                        }
                      "
                    ></el-input>
                  </el-form-item>
                  <el-form-item
                    v-if="ruleForm.spec.target_type !== 'campaign'"
                    class="mr-10"
                    label="是否同时创建新系列"
                    ><el-switch v-model="ruleItems.copy_option.create_campaign"> </el-switch
                  ></el-form-item>
                </div>
              </el-tab-pane>
              <el-tab-pane
                key="add"
                name="add"
              >
                <span slot="label">
                  <i class="el-icon-plus"></i>
                </span>
              </el-tab-pane>
            </el-tabs>
          </div>
          <div class="bg-theme block top-border">
            <p>规则设置</p>
            <el-form-item
              label="执行周期"
              required
            >
              <el-checkbox
                :indeterminate="isWeeksIndeterminate"
                v-model="weeksCheckAll"
                @change="handleWeeksCheckAllChange"
                >全选</el-checkbox
              >
              <el-checkbox-group v-model="ruleForm.spec.weeks">
                <el-checkbox
                  :label="week.value"
                  v-for="week in weekList"
                  :key="week.value"
                  @change="weekChange"
                  >{{ week.label }}</el-checkbox
                >
              </el-checkbox-group>
            </el-form-item>
            <el-form-item
              label="执行时间点（每一天在设置的节点抓取花费并执行规则，会延后约20分钟发送通知）"
              required
            >
              <el-checkbox
                :indeterminate="isIndeterminate"
                v-model="checkAll"
                @change="handleCheckAllChange"
                >全选</el-checkbox
              >
              <el-checkbox-group v-model="ruleForm.spec.hours">
                <el-checkbox
                  :label="hour"
                  v-for="hour in hours"
                  :key="hour"
                  @change="hourChange"
                  >{{ hour < 10 ? '0' : '' }}{{ hour }}:00</el-checkbox
                >
              </el-checkbox-group>
            </el-form-item>
            <el-form-item
              label="状态"
              required
            >
              <el-select
                v-model="ruleForm.status"
                placeholder="请选择活动区域"
              >
                <el-option
                  v-for="(item, index) in statusList"
                  :label="item"
                  :value="item"
                  :key="index"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
        </el-form>
      </el-main>
    </el-container>
    <el-footer></el-footer>
    <div class="bottomBox">
      <el-button @click="handleCloseNow">关闭</el-button>
      <el-button
        type="primary"
        @click="handleUpdateOrCreate"
        >确认</el-button
      >
    </div>
  </el-drawer>
</template>

<script>
import _ from 'lodash';
import {
  rulesCreate,
  rulesUpdate,
  gimpAreaList,
  gimpCountryList,
  gimpCurrencyList,
  getCampaignList,
} from '@/api/automaticRules';
// import '../selectOptionList.js'
// const selectOptionList = require('../selectOptionList.json');
import commonData from './commonData';
import expression from './expression.vue';
export default {
  props: {
    ruleChangeForm: {
      type: Object,
      default: () => {},
    },
  },
  mixins: [commonData],
  components: { expression },
  data() {
    return {
      isOpenAddAutoRulesDraw: false, //是否打开弹窗
      isObjectSubdivision: false, //是否有作用对象细分
      ruleForm: {
        status: '开启', //读写，开启，暂停
        spec: {
          platform: 'facebook', //平台
          target_type: 'campaign', //作用对象
          attr_filters: [
            //细分条件
            {
              desc: '系列',
              field: 'campaign_ids',
              operator: '包含',
              value: '',
              type: 'input',
            },
          ],
          hours: [0],
          weeks: [0, 1, 2, 3, 4, 5, 6],
          name: '',
          rule_items: [
            {
              name: '操作1',
              editName: '操作1',
              date_preset: '当天', //时间周期 '当天', '最近2天', '最近3天', '最近4天', '最近5天', '最近6天', '最近7天'
              action_type: '仅发送通知', //执行操作 暂停 仅发送通知 调整日预算【显示日预算、最大日算和调整频率】
              action_interval_minute: '1', //动作间隔，分钟
              change_budget: {
                budget: '提高',
                //总预算
                budget_type: '日预算', //预算类型 日预算
                change_type: 'AMOUNT', //改变类型 AMOUNT ，加金额，PERCENT 加百分比
                change_value: 0, //金额或百分比
                max_value: '', //预算上限
                min_value: '', //预算下限
              },
              metric_filters: [
                //条件
                {
                  desc: '加购',
                  field: 'checkout',
                  operator: '大于',
                  value: 1,
                  date_preset: '默认',
                  value_type: 'normal',
                },
              ],
              copy_option: {
                copies: 1, //复制份数
                campaign_daily_budget: null, //系列日预算
                adset_daily_budget: null, //组日预算
                campaign_bid_amount: null, //系列竞价或出价
                adset_bid_amount: null, //组竞价或出价
                create_campaign: false, //是否同时创建新系列
              },
              editNamePopVisible: false,
            },
          ],
        },
      },
      rules: {
        'spec.name': [
          { required: true, message: '请输入规则名称', trigger: ['blur'] },
          { min: 1, max: 40, message: '长度在 1 到 40 个字符', trigger: ['blur', 'change'] },
        ],
      },
      weeksCheckAll: true,
      isWeeksIndeterminate: false, //checkbox 的不确定状态，一般用于实现全选的效果
      currencyList: [],
      countryList: [],
      teamIdList: [],
      hours: Array.from({ length: 24 }, (v, i) => i),
      actionInterval: [
        {
          label: '立即执行(1分钟后)',
          value: '1',
        },
      ],
      id: '', //修改的时候带上id参数
      // ignoreFirstChange: true, //修改的时候第一次展示原始值
      originalTitle: '', //修改的时候第一次展示原始值
      isIndeterminate: true, //checkbox 的不确定状态，一般用于实现全选的效果
      checkAll: false, //是否全选
      editableTabsValue: '', //当前显示的标签页名称
      editNamePopVisible: false,
      campaignList: [],
      campaignNameList: [],
    };
  },
  computed: {
    // rulesTitle() {
    //   const targetTitle = this.ruleForm.spec.target_type === 'campaign' ? '广告系列' : '广告组';
    //   const attrFilterTitle = this.isObjectSubdivision ? this.getAttrFilterTitle : '';
    //   const metricFilterTitle = this.ruleForm.spec.metric_filters
    //     .map(
    //       (item) =>
    //         `${this.getLabelByValue(item.field, this.metricFiltersList, 'field', 'desc')}${item.operator}${item.value}`,
    //     )
    //     .join('且');
    //   return (
    //     this.platformTitle +
    //     targetTitle +
    //     attrFilterTitle +
    //     '，' +
    //     metricFilterTitle +
    //     '，' +
    //     this.ruleForm.spec.action_type
    //   );
    // },
    // getAttrFilterTitle() {
    //   let result = [];
    //   this.ruleForm.spec.attr_filters.forEach((element) => {
    //     const name = `${this.getLabelByValue(element.field, this.attrFiltersList, 'field', 'desc')}`;
    //     if (element.field === 'campaign_ids' || element.field === 'account_ids') {
    //       result.push(`${name}为${element.value}`);
    //     }
    //     let value = this.toArr(element.value);
    //     if (element.field === 'account_currency' && value && value.length > 0) {
    //       result.push(
    //         `${name}为${value
    //           .map((item) => `${this.getLabelByValue(item, this.currencyList, 'code', 'name')}`)
    //           .join('或')}`,
    //       );
    //     }
    //     if (element.field === 'country_codes' && value && value.length > 0) {
    //       result.push(
    //         `${name}为${value
    //           .map((item) => `${this.getLabelByValue(item, this.countryList, 'code', 'name')}`)
    //           .join('或')}`,
    //       );
    //     }
    //     if (element.field === 'area_ids' && value && value.length > 0) {
    //       result.push(
    //         `${name}为${value
    //           .map((item) => `${this.getLabelByValue(item, this.teamIdList, 'id', 'name')}`)
    //           .join('或')}`,
    //       );
    //     }
    //   });
    //   return result.join('且');
    // },
    platformTitle() {
      switch (this.ruleForm.spec.platform) {
        case 'facebook':
          return 'FB';
        case 'google':
          return '谷歌';
        case 'tiktok':
          return 'TT';
        case 'twitter':
          return '推特';
        default:
          break;
      }
    },
    metricFiltersList() {
      if (this.ruleForm.spec.target_type == 'campaign') {
        return this.commonMetricFiltersList;
      } else {
        return [...this.commonMetricFiltersList, ...this.adsetMetricFiltersList];
      }
    },
  },
  watch: {
    // rulesTitle: {
    //   handler(val) {
    //     if (!this.id) {
    //       this.ruleForm.spec.name = val;
    //       // console.log(val, '添加rulesTitle');
    //     }
    //   },
    //   deep: true,
    //   // immediate: true,
    // },
    isOpenAddAutoRulesDraw: {
      handler(val) {
        if (val) {
          this.editableTabsValue = this.ruleForm.spec.rule_items[0].name;
          //console.log(this.ruleForm.spec,this.id,'this.ruleForm.spec')
          // if(!this.id){
          this.ruleForm.spec.name = '';
          // this.$refs.rulesForm.resetFields();
          // }
        }
      },
      deep: true,
      // immediate: true,
    },
    'ruleForm.spec.change_budget.change_value': function (newVal, oldVal) {
      if (/^-/.test(newVal)) {
        this.budget = '降低';
      }
    },
    // 'ruleForm.spec.name': function (newVal, oldVal) {
    //   // console.log(newVal, oldVal, 'ruleForm.spec.name');
    //   if (this.id) {
    //     this.ruleForm.spec.name = this.originalTitle;
    //     return;
    //   }
    // },
    ruleChangeForm: {
      handler(val) {
        //console.log(val,'val');
        this.originalTitle = val.title;
        this.id = val.id;
        this.ruleForm.status = val.status;
        this.ruleForm.spec = val.spec;
        this.ruleForm.spec.attr_filters = this.ruleForm.spec.attr_filters.filter((item) =>
          this.attrFiltersList.find((fItem) => fItem.field === item.field),
        );
        if (this.ruleForm.spec.attr_filters.length > 0) {
          this.isObjectSubdivision = true;
          this.ruleForm.spec.attr_filters.forEach((element) => {
            if (
              element.field === 'campaign_ids' ||
              element.field === 'area_ids' ||
              element.field === 'country_codes' ||
              element.field === 'account_currency' ||
              element.field === 'smart_promotion_type'
            ) {
              element.value = element.value.split(',');
              // console.log(element.value,'element.value')
            }
          });
        }
        this.editableTabsValue = this.ruleForm.spec.rule_items[0].name;

        this.ruleForm.spec.rule_items.map((ruleItem) => {
          ruleItem.metric_filters.map((metricFilters) => {
            // console.log(!metricFilters.hasOwnProperty('date_preset'), '!metricFilters.hasOwnProperty');
            if (!metricFilters.hasOwnProperty('date_preset')) {
              // metricFilters.date_preset = '默认';
              this.$set(metricFilters, 'date_preset', '默认');
            }
          });
          // ruleItem.editNamePopVisible = false;
          this.$set(ruleItem, 'editNamePopVisible', false);
          this.$set(ruleItem, 'editName', ruleItem.name);
          // ruleItem.editName = ruleItem.name;

          if (ruleItem.action_type == '调整日预算' || ruleItem.action_type == '调整竞价') {
            if (/^-/.test(ruleItem.change_budget.change_value)) {
              this.$set(ruleItem.change_budget, 'budget', '降低');
            } else {
              this.$set(ruleItem.change_budget, 'budget', '提高');
            }
          }
          if (!ruleItem.change_budget || !ruleItem.change_budget.hasOwnProperty('budget')) {
            this.$set(ruleItem, 'change_budget', {
              budget: '提高',
              //总预算
              budget_type: '日预算', //预算类型 日预算
              change_type: 'AMOUNT', //改变类型 AMOUNT ，加金额，PERCENT 加百分比
              change_value: 0, //金额或百分比
              max_value: '', //预算上限
              min_value: '', //预算下限
            });
          }
          // console.log(ruleItem, 'ruleItem');

          ruleItem.change_budget.change_value = Math.abs(ruleItem.change_budget.change_value);
          //传null后端返回0，编辑的时候重新设置一下
          if (ruleItem.copy_option) {
            for (let key in ruleItem.copy_option) {
              if (ruleItem.copy_option.hasOwnProperty(key)) {
                if (ruleItem.copy_option[key] == 0) {
                  ruleItem.copy_option[key] = null;
                }
              }
            }
          }
        });
        if (!this.ruleForm.spec.hasOwnProperty('weeks')) {
          this.ruleForm.spec.weeks = this.weekList.map((v) => v.value);
        }
      },
    },
  },
  created() {
    this.getGimpLists();

    this.dealDateList();
    // this.getCampaignList()
  },
  methods: {
    async remoteMethod(query, value) {
      if (!query) {
        return;
      }
      let arr = await this.f_getCampaignList(query);
      this.campaignList = this.campaignList.filter((v) => value.includes(v.campaignId)).concat(arr);
      console.log(this.campaignList, arr, value, 'arr');
    },
    changeCampaign(val) {
      console.log(this.ruleForm.spec.attr_filters, val, 'val');
    },
    async remoteMethodCampaignName(query) {
      if (!query) {
        return;
      }
      this.campaignNameList = await this.f_getCampaignList(query);
      //  = this.campaignList.concat(arr);
      console.log(this.campaignNameList, 'arr');
    },
    async f_getCampaignList(keyword) {
      let params = {
        platform: this.ruleForm.spec.platform,
        keyword,
        // keyword:'大容量双层干湿分离旅行包'
      };
      try {
        let res = await getCampaignList(params);
        console.log(res, 'getCampaignList');
        if (res.code == 0) {
          return res.data; // 返回 res.data
        } else {
          throw new Error('Error code: ' + res.code);
        }
      } catch (error) {
        console.error('Error fetching campaign list:', error);
        return []; // 或者根据需要返回其他值
      }
    },
    //获取广告组列表
    getAdGroupList() {
      getAdGroupList().then((res) => {
        console.log(res, 'getAdGroupList');
      });
    },
    //使用表达式
    f_expressionSure(ruleItemsIndex, metricIndex, value) {
      // console.log(ruleItemsIndex, metricIndex, value, 'value');
      this.ruleForm.spec.rule_items[ruleItemsIndex].metric_filters[metricIndex].value = value;
      this.ruleForm.spec.rule_items[ruleItemsIndex].metric_filters[metricIndex].value_type = 'expression';
    },
    //使用数值
    useNormal(item) {
      item.value_type = 'normal';
      item.value = 1;
    },
    changeExpression(metricIndex, value,ruleItemsIndex) {
      console.log(this.$refs,metricIndex, value, this.$refs[`expression-${ruleItemsIndex}-${metricIndex}`]);
      let val = typeof value === 'number' ? '' : value;
      this.$refs[`expression-${ruleItemsIndex}-${metricIndex}`][0].showPopover(val);
    },
    dealDateList() {
      this.dateInsideList = [...['默认'], ...this.dateList];
      this.dateOrderList = [...['默认'], ...this.dateList, ...['上1小时']];
      // this.dateInsideList = [{ label: '默认' }, ...this.dateList.map((item) => ({ label: item, value: item }))];
      // this.dateOrderList = [
      //   { label: '默认' },
      //   ...this.dateList.map((item) => ({ label: item, value: item })),
      //   { label: '上1小时', value: '上1小时' },
      // ];
    },
    //订单的数组多一个上1小时
    attrChangeableList(field) {
      //console.log(field, field.listName, this[field.listName], 'field');
      return this[field.listName];
    },
    handleKeyDown(event) {
      //console.log(event.keyCode, 'event.keyCode');
      // if (event.keyCode === 46) {
      //   // 46 是删除键的 keyCode
      //   event.stopPropagation();
      //   event.preventDefault();
      // }
    },
    //删除标签页
    removeTab(targetName) {
      //console.log('removeTab');
      let tabs = this.ruleForm.spec.rule_items;
      let activeName = this.editableTabsValue;
      if (activeName === targetName) {
        tabs.forEach((tab, index) => {
          if (tab.name === targetName) {
            let nextTab = tabs[index + 1] || tabs[index - 1];
            if (nextTab) {
              activeName = nextTab.name;
            }
          }
        });
      }

      this.editableTabsValue = activeName;
      this.ruleForm.spec.rule_items = tabs.filter((tab) => tab.name !== targetName);
    },
    // 添加标签
    addTab(targetName) {
      let newTabName = `操作${this.ruleForm.spec.rule_items.length + 1}`;
      this.ruleForm.spec.rule_items.push({
        name: newTabName,
        editName: newTabName,
        date_preset: '当天', //时间周期 '当天', '最近2天', '最近3天', '最近4天', '最近5天', '最近6天', '最近7天'
        action_type: '仅发送通知', //执行操作 暂停 仅发送通知 调整日预算【显示日预算、最大日算和调整频率】
        action_interval_minute: '1', //动作间隔，分钟
        change_budget: {
          budget: '提高',
          //总预算
          budget_type: '日预算', //预算类型 日预算
          change_type: 'AMOUNT', //改变类型 AMOUNT ，加金额，PERCENT 加百分比
          change_value: 0, //金额或百分比
          max_value: '', //预算上限11
          min_value: '', //预算下限 11
        },
        metric_filters: [
          //条件
          {
            desc: '加购',
            field: 'checkout',
            operator: '大于',
            value: 1,
            date_preset: '默认',
            value_type: 'normal',
          },
        ],
        copy_option: {
          copies: 1, //复制份数
          campaign_daily_budget: null, //系列日预算
          adset_daily_budget: null, //组日预算
          campaign_bid_amount: null, //系列竞价或出价
          adset_bid_amount: null, //组竞价或出价
          create_campaign: false,
        },
        editNamePopVisible: false,
      });
      this.editableTabsValue = newTabName;
    },
    /* 活动标签切换时触发 */
    beforeLeave(currentName, oldName) {
      // console.log(currentName, oldName, 'beforeLeave');
      //重点，如果name是add，则什么都不触发
      if (currentName == 'add') {
        this.addTab();
        return false;
      } else {
        this.editableTabsValue = currentName;
      }
    },
    //点击标签页的编辑按钮，显示输入框，自动获取焦点
    editTabsName(item, index) {
      //console.log('editTabsName', item);
      item.editName = item.name;
      // item.editNamePopVisible = !item.editNamePopVisible;
      // setTimeout(() => {
      //   if (this.$refs.editNameInput) {
      //     console.log(index, this.$refs.editNameInput);
      //     this.$refs.editNameInput.map((v, vindex) => {
      //       console.log(vindex);

      //       if (v.value == item.editName) {
      //         this.$refs.editNameInput[vindex].focus();
      //       } else {
      //         this.$refs.editNameInput[vindex].blur();
      //       }
      //     });
      //   }
      // }, 100);
    },
    //编辑标签页输入框失去焦点
    handelTabNameInputBlur(item) {
      // console.log(
      //   'handelTabNameInputBlur',
      //   item.editName.length < 21 && this.checkDuplicates(this.ruleForm.spec.rule_items),
      //   item.editNamePopVisible,
      // );
      if (item.editName.length < 21 && this.checkDuplicates(this.ruleForm.spec.rule_items)) {
        item.editNamePopVisible = !item.editNamePopVisible;
        // console.log(item.editNamePopVisible);
      }
    },
    //编辑标签页输入框按回车或者失去焦点
    handelTabNameInputChange(item, index) {
      // console.log('handelTabNameInputChange', this.ruleForm.spec.rule_items);
      if (item.editName.length > 20) {
        this.$message.info('标签名称不可以超过20个字哦~');
        return;
      } else {
        // console.log(this.checkDuplicates(this.ruleForm.spec.rule_items));
        if (this.checkDuplicates(this.ruleForm.spec.rule_items)) {
          this.editableTabsValue = item.editName;
          // console.log(this.editableTabsValue, 'this.editableTabsValue');
          if (item.editName) {
            item.name = item.editName;
            // this.$set(this.ruleForm.spec.rule_items[index], 'name', item.editName)
          }
          // console.log(this.ruleForm.spec.rule_items, 'this.ruleForm.spec.rule_items')
          // console.log(this.$refs.elTabs, 'this.$refs.elTabs')
          // this.$refs.elTabs.currentName = item.editName;
          // this.$set(this.$refs.elTabs, 'currentName', item.editName);
          // this.$forceUpdate();
          // this.$refs.elTabs.setCurrentName(item.editName);
          // console.log(this.$refs.elTabs.setCurrentName)
          // console.log(this.$refs.elTabs, 'set')
          // item.editNamePopVisible = !item.editNamePopVisible;
        } else {
          this.$message.info('标签名不可以重复哦~');
        }
      }
    },
    //检验tab是否重复
    checkDuplicates(arr) {
      const names = arr.map((item) => item.editName);
      const uniqueNames = new Set(names);
      return names.length === uniqueNames.size;
    },
    //处理数组
    toArr(value) {
      // 使用 Array.isArray() 来检查值是否为数组
      if (!Array.isArray(value)) {
        // 如果 value 是一个字符串，则使用 ',' 分隔符将它分割成数组
        if (typeof value === 'string') {
          return value.split(',');
        }
        // 将其他非数组类型的值转换为只包含原始值的单元素数组
        else {
          return [value];
        }
      }
      // 如果 value 已经是数组，则直接返回
      return value;
    },
    //执行时间点检查是否全选
    handleCheckAllChange(val) {
      this.ruleForm.spec.hours = val ? this.hours : [];
      this.isIndeterminate = false;
    },
    //执行周期检查是否全选
    handleWeeksCheckAllChange(val) {
      this.ruleForm.spec.weeks = val ? this.weekList.map((v) => v.value) : [];
      this.isWeeksIndeterminate = false;
    },
    hourChange(val) {
      if (val.length == this.hours.length) {
        this.isIndeterminate = false;
      } else {
        this.isIndeterminate = true;
      }
    },
    //执行周期
    weekChange() {
      if (this.ruleForm.spec.weeks.length == this.weekList.length) {
        this.isWeeksIndeterminate = false;
      } else {
        this.isWeeksIndeterminate = true;
      }
    },
    //点击确认按钮，进行接口提交前的校验和数据处理
    handleUpdateOrCreate() {
      this.$refs.rulesForm.validate((valid) => {
        // console.log(valid);
        if (valid) {
          if (this.ruleForm.spec.hours.length == 0) {
            this.$message.warning(`请设置执行时间点~`);
            return;
          }
          if (this.ruleForm.spec.weeks.length == 0) {
            this.$message.warning(`请设置执行周期~`);
            return;
          }
          //如果选了细分条件，则检验并且传递，id由逗号隔开，将中文逗号替换成英文逗号，没有选就置空
          if (this.isObjectSubdivision) {
            // console.log(this.ruleForm.spec.attr_filters, 'this.ruleForm.spec.attr_filters');
            for (let i = 0; i < this.ruleForm.spec.attr_filters.length; i++) {
              let item = this.ruleForm.spec.attr_filters[i];
              // console.log(item, 'item');
              if (!item.value || item.value.length == 0) {
                // console.log(item);
                this.$message.warning(
                  `请填写${this.getLabelByValue(item.field, this.attrFiltersList, 'field', 'desc')}选项`,
                );
                return;
              }
            }
            for (let i = 0; i < this.ruleForm.spec.attr_filters.length; i++) {
              let item = this.ruleForm.spec.attr_filters[i];
              // item.operator = '包含';
              if (
                item.field === 'account_ids' ||
                item.field === 'product_ids' ||
                item.field === 'sale_ids' ||
                item.field === 'site_ids'
              ) {
                console.log('item.value', item.value);
                item.value = item.value.replace(/[\s\n\t]+/g, '').replace('，', ',');
                if (!this.validateInput(item.value, this.ruleForm.spec.platform)) {
                  return this.$message.warning(
                    `${this.getLabelByValue(
                      item.field,
                      this.attrFiltersList,
                      'field',
                      'desc',
                    )}选项只能输入英文逗号或数字` +
                      `${this.ruleForm.spec.platform === 'snapchat' ? '或小写英文字母或者-' : ''}`,
                  );
                }
              } else {
                if (item.value && item.value.length > 0) {
                  item.value = item.value.toString();
                }
              }
            }
          } else {
            this.ruleForm.spec.attr_filters = [];
          }
          try {
            this.ruleForm.spec.rule_items.map((ruleItems) => {
              if(ruleItems.metric_filters.length === 0){
                this.$message.warning( `${ruleItems.name}的指标条件不能为空`)
                throw new Error();
              }
              ruleItems.metric_filters.map((metricFilters) => {
                console.log(Number(metricFilters.value), 'Number(metricFilters.value)');
                const isInt = this.metricFiltersList.filter((item) => item.field === metricFilters.field)[0].isInt;
                if (
                  metricFilters.value_type !== 'expression' &&
                  isInt &&
                  !Number.isInteger(Number(metricFilters.value))
                ) {
                  this.$message.warning(
                    `${ruleItems.name}的${this.getLabelByValue(
                      metricFilters.field,
                      this.metricFiltersList,
                      'field',
                      'desc',
                    )}必须是整数~`,
                  );
                  throw new Error();
                }
                if (metricFilters.date_preset == '默认') {
                  metricFilters.date_preset = null;
                }
              });
              if (ruleItems.action_interval_minute == 1) {
                ruleItems.action_interval_minute = 60;
              }
              // console.log(ruleItems.change_budget, 'ruleItems.change_budget');
              //处理降低时候传负值
              if (ruleItems.action_type === '调整日预算' || ruleItems.action_type === '调整竞价') {
                if (!ruleItems.change_budget.change_value && typeof ruleItems.change_budget.change_value !== 'number') {
                  this.$message.warning(`请设置${ruleItems.name}的日预算~`);
                  throw new Error();
                }
                if (ruleItems.change_budget.budget === '降低') {
                  if (!/^-/.test(ruleItems.change_budget.change_value)) {
                    ruleItems.change_budget.change_value = `-${ruleItems.change_budget.change_value}`;
                  }
                  ruleItems.change_budget.max_value = '';
                } else {
                  ruleItems.change_budget.change_value = Math.abs(ruleItems.change_budget.change_value);
                  ruleItems.change_budget.min_value = '';
                }
              } else {
                // console.log(ruleItems, 'ruleItems.change_budget');
                if (ruleItems.change_budget) {
                  ruleItems.change_budget.change_value = 0;
                  ruleItems.change_budget.max_value = '';
                  ruleItems.change_budget.min_value = '';
                }
              }
            });
          } catch (error) {
            // console.log(error, 'error');
            return;
          }

          if (this.id) {
            this.ruleForm.id = this.id;
            this.rulesUpdate();
          } else {
            this.id = '';
            this.ruleForm.id = '';
            this.rulesCreate();
          }
        } else {
          // console.log('error submit!!');
          return false;
        }
      });
    },
    validateInput(input, platform) {
      // 正则表达式匹配数字和英文逗号
      let validPattern = /^[0-9,]*$/;
      if (platform === 'snapchat') {
        validPattern = /^[0-9a-z,-]*$/;
      }
      // 检查输入是否符合正则表达式
      if (!validPattern.test(input)) {
        return false;
      }

      return true;
    },
    // 自动规则-创建规则
    rulesCreate() {
      this.$showLoading();
      rulesCreate(this.ruleForm).then((res) => {
        this.$hideLoading();
        if (res.code === 0) {
          this.$message.success(`添加成功`);
          this.$emit('getList');
          this.handleCloseNow();
        }
      });
    },
    // 自动规则-修改规则
    rulesUpdate() {
      this.$showLoading();
      rulesUpdate(this.ruleForm).then((res) => {
        this.$hideLoading();
        if (res.code === 0) {
          this.$message.success(`修改成功`);
          this.id = '';
          this.ruleForm.id = '';
          this.handleCloseNow();
          this.$emit('getList');
        }
      });
    },
    checkOptions(rule, value, callback) {
      // console.log(value, 'value');
    },
    validateMin(item) {
      // console.log(item, 'item');
      if (item.value0 < 0.01) {
        item.value0 = 0.01;
      } else if (item.value0 > 100) {
        item.value0 = 100;
      }
      if (item.value0 < 0.01 || item.value0 > 100) {
        this.$message(`${item.desc}条件的最小值应在0.01至100之间`);
      }
    },
    validateMax(item) {
      if (item.value1 < 0.01) {
        item.value1 = 0.01;
      } else if (item.value1 > 100) {
        item.value1 = 100;
      }
      if (item.value1 < 0.01 || item.value1 > 100) {
        this.$message(`${item.desc}条件的最大值应在0.01至100之间`);
      } else if (item.value1 < item.value0) {
        this.$message(`${item.desc}条件的最大值不能小于最小值`);
      }
    },
    //将各自的value值改为label名称
    getLabelByValue(value, array, code, name) {
      for (let item of array) {
        if (item[code] == value) {
          return item[name];
        }
      }
      // let obj = array.find((item) => (item[code] ? item[code] : item.id == value));
      // console.log(obj);
      // return obj ? obj[name] : null;
    },
    //添加细分条件
    addAttrFilters() {
      const nextItem = this.attrFiltersList.find(
        (item) => !this.ruleForm.spec.attr_filters.some((curr) => curr.field === item.field),
      );

      if (nextItem) {
        // console.log(nextItem, 'nextItem');
        this.ruleForm.spec.attr_filters.push({ ...nextItem });
        this.updateAttrFiltersList();
      } else {
        this.$message(`已经没有条件可以再添加了哦~`);
      }

      // this.ruleForm.spec.attr_filters.push(JSON.parse(JSON.stringify(this.attrFiltersList[0])));

      // console.log(this.ruleForm.spec.attr_filters, 'ruleForm.spec.attr_filters');
    },
    //更新选项的禁用状态
    updateAttrFiltersList() {
      for (let listItem of this.attrFiltersList) {
        const foundIndex = this.ruleForm.spec.attr_filters.findIndex(
          (filterItem) => listItem.field === filterItem.field,
        );
        if (foundIndex !== -1) {
          // 如果找到了匹配项，则添加或更新'disabled'为true
          this.$set(listItem, 'disabled', true);
        } else {
          // 否则，如果对象中已经有'disabled'属性，则设置为false
          if ('disabled' in listItem) {
            this.$set(listItem, 'disabled', false);
          }
        }
      }
    },
    // 细分条件选项框发生变化的时候
    attrFiltersSelectChange(value, index) {
      // console.log(value, index,'value, index');
      if (value === 'campaign_name') {
        this.$set(this.ruleForm.spec.attr_filters[index], 'operator', '包含字符');
        // this.ruleForm.spec.attr_filters[index].operator = '包含';
      } else {
        this.$set(this.ruleForm.spec.attr_filters[index], 'operator', '包含');
        // this.ruleForm.spec.attr_filters[index].operator = '包含字符';
      }
      this.ruleForm.spec.attr_filters[index].value = '';
      // const option = this.attrFiltersList.find((option) => option.field === value);
      // if (option) {
      //   this.ruleForm.spec.attr_filters[index].desc = option.desc;
      // }
      // this.ruleForm.spec.attr_filters[index].value = '';
      this.ruleForm.spec.attr_filters.forEach((item) => {
        let matchItem = this.attrFiltersList.find((attr) => attr.field === item.field);
        if (matchItem) {
          item.desc = matchItem.desc;
        }
      });
      this.updateAttrFiltersList();
    },
    handleOptionCurrencyChange(val) {
      // console.log(val);
    },
    //删除细分条件
    delAttrFilters(index) {
      if (this.ruleForm.spec.attr_filters.length == 1) {
        this.$message(`细分条件至少选一项哦~`);
        return;
      }
      this.ruleForm.spec.attr_filters.splice(index, 1);
      this.updateAttrFiltersList();
    },
    //删除条件
    delMetricFilters(ruleItemsIndex, metricIndex) {
      this.ruleForm.spec.rule_items[ruleItemsIndex].metric_filters.splice(metricIndex, 1);
      // this.updateMetricFiltersList();
    },
    //添加条件
    addMetricFilters(ruleItemsIndex) {
      // console.log(this.metricFiltersList, 'this.metricFiltersList');
      const nextItem = this.metricFiltersList.find(
        (item) =>
          !this.ruleForm.spec.rule_items[ruleItemsIndex].metric_filters.some((curr) => curr.field === item.field),
      );

      if (nextItem) {
        // console.log(nextItem, 'nextItem');
        this.ruleForm.spec.rule_items[ruleItemsIndex].metric_filters.push({ ...nextItem });
        // console.log(this.ruleForm.spec, ruleItemsIndex);
        // if (
        //   nextItem.field == 'order_count' &&
        //   this.ruleForm.spec.rule_items[ruleItemsIndex].dateInsideList[
        //     this.ruleForm.spec.rule_items[ruleItemsIndex].dateInsideList.length - 1
        //   ] !== '上1小时'
        // ) {
        //   this.ruleForm.spec.rule_items[ruleItemsIndex].dateInsideList.push('上1小时');
        // }
        // this.updateMetricFiltersList();
      } else {
        // this.$message(`已经没有条件可以再添加了哦~`);
        this.ruleForm.spec.rule_items[ruleItemsIndex].metric_filters.push(
          JSON.parse(JSON.stringify(this.metricFiltersList[0])),
        );
      }
    },
    metricFiltersChange(item, ruleItems) {
      console.log(item, ruleItems, 'item, ruleItems');

      // if(this.ruleForm.spec.target_type == 'adset'){
      //   const foundIndex = this.adsetMetricFiltersList.findIndex(
      //     (filterItem) => item.field === filterItem.field,
      //   );
      //   if (foundIndex !== -1) {
      //     // 如果找到了匹配项，则添加或更新'disabled'为true
      //     this.ruleForm.spec.target_type = 'campaign'
      //   }
      // }
      // if (item.field == 'order_count' && ruleItems.dateInsideList[ruleItems.dateInsideList.length - 1] !== '上1小时') {
      //   ruleItems.dateInsideList.push('上1小时');
      // }
      // if (item.field !== 'order_count' && ruleItems.dateInsideList[ruleItems.dateInsideList.length - 1] == '上1小时') {
      //   ruleItems.dateInsideList.pop();
      // }
      // if (item.field !== 'order_count' && item.date_preset == '上1小时') {
      //   item.date_preset = '默认';
      // }
    },
    //切换执行操作
    actionTypeChange(ruleItems, ruleItemsIndex) {
      if (ruleItems.action_type == '调整日预算' && ruleItems.change_budget.change_type == 'SET') {
        this.$set(this.ruleForm.spec.rule_items[ruleItemsIndex].change_budget, 'change_type', 'AMOUNT');
      }
      if (ruleItems.action_type !== '调整竞价' || ruleItems.action_type !== '调整日预算') {
        this.$set(this.ruleForm.spec.rule_items[ruleItemsIndex].change_budget, 'change_value', 0);
        this.$set(this.ruleForm.spec.rule_items[ruleItemsIndex].change_budget, 'max_value', '');
        this.$set(this.ruleForm.spec.rule_items[ruleItemsIndex].change_budget, 'min_value', '');
      }
      if (ruleItems.action_type == '调整日预算') {
        this.$set(this.ruleForm.spec.rule_items[ruleItemsIndex].change_budget, 'budget_type', '日预算');
      }
      if (ruleItems.action_type == '调整竞价') {
        this.$set(this.ruleForm.spec.rule_items[ruleItemsIndex].change_budget, 'budget_type', '竞价');
      }
      console.log(
        ruleItems.action_type,
        'ruleItems.action_type',
        this.ruleForm.spec.rule_items[ruleItemsIndex].change_budget.budget_type,
      );
    },
    //切换调整类型
    changeBudgetType(ruleItems, ruleItemsIndex) {
      if (
        (ruleItems.action_type == '调整竞价' || ruleItems.action_type == '调整日预算') &&
        ruleItems.change_budget.change_type == 'SET'
      ) {
        this.$set(this.ruleForm.spec.rule_items[ruleItemsIndex].change_budget, 'budget', '提高');
        this.$set(this.ruleForm.spec.rule_items[ruleItemsIndex].change_budget, 'change_value', 0);
        this.$set(this.ruleForm.spec.rule_items[ruleItemsIndex].change_budget, 'max_value', '');
        this.$set(this.ruleForm.spec.rule_items[ruleItemsIndex].change_budget, 'min_value', '');
      }
    },
    updateMetricFiltersList(item) {
      if (item) {
        item.value = 1;
      }
      for (let listItem of this.ruleForm.spec.rule_items) {
        const foundIndex = this.ruleForm.spec.rule_items.findIndex((filterItem) => listItem.field === filterItem.field);
        if (foundIndex !== -1) {
          // 如果找到了匹配项，则添加或更新'disabled'为true
          this.$set(listItem, 'disabled', true);
        } else {
          // 否则，如果对象中已经有'disabled'属性，则设置为false
          if ('disabled' in listItem) {
            this.$set(listItem, 'disabled', false);
          }
        }
      }
    },
    // 平台改变 暂时Facebook tiktok line 支持广告组和广告系列 line的广告组不支持毛利
    handlePlatformChange() {
      if (this.ruleForm.spec.platform != 'facebook' && this.ruleForm.spec.platform != 'tiktok') {
        this.ruleForm.spec.target_type = 'campaign';
      }
      console.log(
        this.ruleForm.spec.attr_filters.filter((item) => item.field == 'campaign_ids'),
        '1111',
      );
      if (this.ruleForm.spec.attr_filters.filter((item) => item.field == 'campaign_ids').length > 0) {
        this.ruleForm.spec.attr_filters.map((item) => {
          if (item.field == 'campaign_ids') {
            item.value = [];
            this.campaignList = [];
          }
        });
      }
    },
    // 作用对象改变tiktok广告组不支持调整日预算
    handelTargetType() {
      if (this.ruleForm.spec.target_type == 'campaign') {
        const matchingFields = this.commonMetricFiltersList.map((item) => item.field);
        for (let listItem of this.ruleForm.spec.rule_items) {
          for (let i = listItem.metric_filters.length - 1; i >= 0; i--) {
            if (!matchingFields.includes(listItem.metric_filters[i].field)) {
              listItem.metric_filters.splice(i, 1);
              this.messageInfo('已自动过滤掉广告组指标，请检查操作条件');
            }
          }
        }
      }
      // if (this.ruleForm.spec.platform == 'tiktok' && this.ruleForm.spec.target_type == 'adset') {
      //   console.log('handelTargetType',this.ruleForm.spec.action_type);
      //   this.ruleForm.spec.action_type = '仅发送通知';
      //   console.log('handelTargetType',this.ruleForm.spec.action_type);
      // }
    },
    messageInfo: _.debounce(function (val) {
      this.$message.info(val);
    }, 1000),
    //获取 团队 国家 币种列表
    getGimpLists() {
      gimpAreaList().then((res) => {
        this.$hideLoading();
        if (res.code == 0) {
          this.teamIdList = res.data;
        }
      });
      gimpCountryList().then((res) => {
        this.$hideLoading();
        if (res.code == 0) {
          this.countryList = res.data;
        }
      });
      gimpCurrencyList().then((res) => {
        this.$hideLoading();
        if (res.code == 0) {
          this.currencyList = res.data;
        }
      });
    },
    //关闭弹窗
    handleClose(done) {
      this.reset();
      done();
      // if (this.id) {
      //   done();
      // } else {
      //   this.$confirm('确认关闭？')
      //     .then((_) => {
      //       done();
      //     })
      //     .catch((_) => {});
      // }
    },
    handleOpen() {
      this.isOpenAddAutoRulesDraw = true;
    },
    handleCloseNow() {
      this.reset();

      this.isOpenAddAutoRulesDraw = false;
    },
    handleInput(value, arrName, keyName) {
      // console.log(value, arrName, keyName);
      //毛利率支持负的
      if (arrName.field == 'pre_profit_rate') {
        this.$set(
          arrName,
          keyName,
          value
            .replace(/[^\d.-]/g, '')
            .replace(/^\./g, '')
            .replace(/\.{2,}/g, '.')
            .replace('.', '$#$')
            .replace(/\./g, '')
            .replace('$#$', '.')
            .replace(/\-{2,}/g, '-')
            .replace(/[0-9]+\-/g, '')
            .replace(/\.[0-9]*\-/g, '.')
            .replace(/^(-)*(\d+)\.(\d\d).*$/, '$1$2.$3'),
        );
      } else {
        this.$set(
          arrName,
          keyName,
          value
            .replace(/[^\d.]/g, '')
            .replace(/^\./g, '')
            .replace(/\.{2,}/g, '.')
            .replace('.', '$#$')
            .replace(/\./g, '')
            .replace('$#$', '.')
            .replace(/^(-)*(\d+)\.(\d\d).*$/, '$1$2.$3'),
        );
      }
    },
    //重置 初始化 清空
    reset() {
      this.id = '';
      this.ruleForm.id = '';
      this.originalTitle = '';
      this.isObjectSubdivision = false;
      // this.ignoreFirstChange = true;
      this.ruleForm.status = '开启';
      this.ruleForm.spec.platform = 'facebook'; //平台
      this.ruleForm.spec.target_type = 'campaign'; //作用对象
      this.ruleForm.spec.attr_filters = [
        //细分条件
        {
          desc: '系列id',
          field: 'campaign_ids',
          operator: '包含',
          value: '',
          type: 'input',
        },
      ];
      this.editableTabsValue = this.ruleForm.spec.rule_items[0].name;
      this.ruleForm.spec.hours = [0];
      this.ruleForm.spec.weeks = [0, 1, 2, 3, 4, 5, 6];
      this.ruleForm.spec.name = '';
      this.$set(this.ruleForm.spec, 'name', '');
      this.ruleForm.spec.rule_items = [
        {
          name: '操作1',
          editName: '操作1',
          date_preset: '当天', //时间周期 '当天', '最近2天', '最近3天', '最近4天', '最近5天', '最近6天', '最近7天'
          action_type: '仅发送通知', //执行操作 暂停 仅发送通知 调整日预算【显示日预算、最大日算和调整频率】
          action_interval_minute: '1', //动作间隔，分钟
          change_budget: {
            budget: '提高',
            //总预算
            budget_type: '日预算', //预算类型 日预算
            change_type: 'AMOUNT', //改变类型 AMOUNT ，加金额，PERCENT 加百分比
            change_value: 0, //金额或百分比
            max_value: '', //预算上限
            min_value: '', //预算下限
          },
          metric_filters: [
            //条件
            {
              desc: '加购',
              field: 'checkout',
              operator: '大于',
              value: 1,
              date_preset: '默认',
              value_type: 'normal',
            },
          ],
          copy_option: {
            copies: 1, //复制份数
            campaign_daily_budget: null, //系列日预算
            adset_daily_budget: null, //组日预算
            campaign_bid_amount: null, //系列竞价或出价
            adset_bid_amount: null, //组竞价或出价
            create_campaign: false, //
          },
          editNamePopVisible: false,
        },
      ];
      // this.metricFiltersList = _.cloneDeep(this.metricFiltersList);
      // this.metricFiltersList.map(v=>v.value = 1)
      this.attrFiltersList = _.cloneDeep(this.attrFiltersList);
      this.attrFiltersList.map((v) => (v.disabled = false));
      this.$refs.rulesForm.resetFields();
      if (this.$refs.expression) {
        this.$refs.expression.map((v) => v.reset());
      }
      this.campaignList = [];
    },
  },
};
</script>

<style lang="scss">
// .edit_tab {
//   .el-tabs__header {
//     .el-tabs__nav {
//       .el-tabs__item:first-child {
//         .el-icon-close {
//           display: none;
//         }
//       }
//     }
//   }
// }
.edit_tab_input {
  width: 150px;

  input {
    width: 100%;
  }
}

.bottomBox {
  width: 900px;
  background-color: #ffffff;
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 10px 30px 10px 0;
  border-top: 1px solid #eeeeee;
  z-index: 1;
  display: flex;
  justify-content: flex-end;

  .el-button {
    margin-left: 20px;
  }
}

.numberInput {
  width: 250px;
}

.operatorInput {
  width: 155px;
}

.inputWd {
  width: 480px;
}

.el-icon-delete {
  cursor: pointer;
}

.el-main {
  padding: 0 20px;
}

.block {
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
  background-color: #fafafa;
  // border-bottom: 1px solid #eeeeee;
}

.detailObject {
  // .el-select{
  width: 500px;
  // }
}

.el-drawer__header {
  border-bottom: 1px solid #eeeeee;
  margin-bottom: 10px;
  padding: 20px;
}

.el-button--text {
  padding-top: 0;
}

.el-form-item {
  margin-bottom: 10px;
}

.top-border {
  border-top: 1px solid #eeeeee;
}

.bottom-border {
  border-bottom: 1px solid #eeeeee;
}

.selectWidth {
  width: 480px;
}
.w-160 {
  width: 168px;
}
.w-270 {
  width: 317px;
}
.lh-20 {
  line-height: 20px;
}
.el-tabs__content {
  overflow: visible;
}
.w-112 {
  width: 90px;
  padding: 0;
  text-align: center;
  margin-right: 5px;
}
.max-h {
  max-height: 90px;
  overflow-y: scroll;
}
.custom-select .el-select__tags {
  max-height: 90px;
  overflow-y: auto;
}
.w-100 {
  width: 110px;
}
</style>
